const modalId = 'alert';

const alert = (el, { Alpine }) => {
  return {
    show: (icon, title, content, callback = () => {}) => {
      const modalStore = Alpine.store('modal');

      if (!modalStore.isOpen(modalId)) {
        open(icon, title, content, callback);
        return;
      }

      modalStore.close(modalId);
      setTimeout(() => open(icon, title, content, callback), 300);
    },
  };
};

const open = (icon, title, content, callback) => {
  const alertStore = Alpine.store('alert');
  const modalStore = Alpine.store('modal');

  if (modalStore.isOpen(modalId)) {
    modalStore.close(modalId);
  }

  alertStore.icon = icon;
  alertStore.title = title;
  alertStore.content = content;
  alertStore.callback = callback;

  modalStore.open(modalId);
};

export default alert;
