bergfex = bergfex || {};
bergfex.promotionZones = {
  skyscraper: 2,
  promotion: 3000,
  content: 46,
  contentFillup: 54,
  leaderboard: 55,
  halfpage: 56,
  mobileBanner31: 57,
  webcamListCard: 60,
};
bergfex.ads = {
  content: {
    height: 270,
    width: 320,
  },
  leaderboard: {
    height: 90,
    width: 728,
  },
  halfpage: {
    height: 600,
    width: 320,
  },
};
