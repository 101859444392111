const confirm = async (el, { expression, modifiers }, { evaluateLater, cleanup }) => {
  const modalId = 'confirm';
  const modalStore = Alpine.store('modal');
  const confirmStore = Alpine.store('confirm');

  const evaluate = evaluateLater(expression ? expression : '{}');

  const open = (data) => {
    const delay = modalStore.isOpen(modalId) ? 300 : 0;

    if (delay) {
      modalStore.close(modalId);
    }

    confirmStore.iconStyle = modifiers.includes('danger') ? 'tw-bg-red' : 'tw-bg-brand';
    confirmStore.icon = data.icon || 'icon-warnings-fill';
    confirmStore.title = data.title || bergfex.confirm.title;
    confirmStore.content = data.content || '';
    confirmStore.cancelLabel = data.cancelLabel || bergfex.confirm.cancel;
    confirmStore.confirmLabel = data.confirmLabel || bergfex.confirm.confirm;

    confirmStore.confirm = () => el.dispatchEvent(new CustomEvent('confirmed', { bubbles: true }));

    setTimeout(() => {
      modalStore.open(modalId);
    }, delay);
  };

  const handler = (event) => {
    event.stopImmediatePropagation();
    evaluate((data) => open(data || {}));
  };

  el.addEventListener('click', handler);
  cleanup(() => el.removeEventListener('click', handler));
};

export default confirm;
