const confirmStore = {
  icon: '',
  iconStyle: '',
  title: '',
  content: '',
  cancelLabel: '',
  confirmLabel: '',
  confirmCallback: () => {},
};

export default confirmStore;
