const userRating = (tourId, ratingId, stars, title, description, successTitle) => ({
  form: {},
  tourId: tourId,
  ratingId: ratingId,
  apiUrl: '/presentation/api/tours/' + tourId + '/rate',
  init() {
    if (this.ratingId) {
      this.form = this.$form('post', this.apiUrl, {
        id: this.ratingId,
        rating: stars,
        title: title,
        description: description,
      });
    } else {
      this.form = this.$form('post', this.apiUrl, {
        rating: '',
        title: '',
        description: '',
      });
    }
  },
  submit() {
    this.form
      .submit()
      .then(() => {
        this.form.reset();

        this.$alert.show('icon-check', successTitle, '', () => {
          const url = new URL(window.location);
          url.searchParams.delete('rate');
          window.history.replaceState(null, '', url);
          window.location.reload();
        });
      })
      .catch((error) => {
        if (error.response?.status !== 422) {
          this.$alert.show('icon-warnings', bergfex.error.headline, bergfex.error.text, () =>
            window.location.reload(),
          );
        }
      });
  },
});

export default userRating;
