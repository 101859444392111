import tagmanager from '../../abstracts/tagmanager.js';

const userRatingDelete = (tourId, successTitle) => ({
  tourId: tourId,
  inProgress: false,
  apiUrl: '/presentation/api/tours/' + tourId + '/rate',
  async deleteRating() {
    if (this.inProgress) {
      return;
    }
    this.inProgress = true;

    const response = await window.axios.delete(this.apiUrl);

    if (response.status === 200) {
      tagmanager.event('tour-detail_rating_delete', {});

      this.$alert.show('icon-check', successTitle, '', () => {
        window.location.reload();
      });
    } else {
      this.$notify.error(bergfex.error.headline, bergfex.error.text);
    }

    this.inProgress = false;
  },
});

export default userRatingDelete;
