import { isResponsiveScreen } from '../../abstracts/responsive';
import tagmanager from '../../abstracts/tagmanager.js';

const filter = (filters) => ({
  filters: filters,
  lazyFilters: filters,
  activeFilter: null,

  originalOrder: Object.keys(filters),

  getFilterPosition(filter) {
    return this.sortedFilters.indexOf(filter);
  },

  init() {
    const watchAndCloseModal = (property, modalName) => {
      this.$watch(
        property,
        (newValue, oldValue) => newValue !== oldValue && Alpine.store('modal').close(modalName),
      );
    };

    watchAndCloseModal('filters.countryOrState', 'tourFilterModal_countryOrState');
    watchAndCloseModal('filters.region', 'tourFilterModal_region');
    watchAndCloseModal('filters.typeOrCategory', 'tourFilterModal_typeOrCategory');
  },

  async commit() {
    Alpine.store('modal').close('filter-modal');

    this.filters = { ...this.lazyFilters };

    await this.$nextTick();

    this.$dispatch('filter-changed', {
      filters: this.filters,
      initiator: this.activeFilter === 'all' ? null : this.activeFilter,
    });
  },

  search(value) {
    if (this.filters.search === value) {
      return;
    }

    this.filters.search = value;

    if (value !== '') {
      tagmanager.event('comp_tour-searchbar_submit', {
        Category: 'tour',
        Selection: false,
      });
    }

    if (isResponsiveScreen()) {
      history.back();
    }

    document.activeElement.blur();
  },

  searchAutoCompleteSelected(url) {
    tagmanager.event('comp_tour-searchbar_submit', {
      Category: 'tour',
      Selection: url,
    });

    window.location.href = url;
  },

  showFilter(filterName) {
    this.lazyFilters = { ...this.filters };

    this.activeFilter = filterName;

    Alpine.store('modal').open('filter-modal');

    this.$dispatch('filter-opened', filterName === 'all' ? null : filterName);
  },

  resetFilters(activeFilter) {
    if (activeFilter === 'all') {
      for (const key in this.filters) {
        if (!['countryOrState', 'region', 'typeOrCategory'].includes(key)) {
          this.lazyFilters[key] = this.resetFilter(this.lazyFilters[key]);
        }
      }
    } else {
      this.lazyFilters[activeFilter] = this.resetFilter(this.lazyFilters[activeFilter]);
    }

    this.$dispatch('filter-reset', activeFilter);
  },

  resetFilter(value) {
    let newValue = null;

    switch (true) {
      case value instanceof Array:
        newValue = [];
        break;
      case value instanceof Object && !(value instanceof Array):
        newValue = {};
        for (const key in value) {
          newValue[key] = this.resetFilter(value[key]);
        }
        break;
    }

    return newValue;
  },

  get sortedFilters() {
    const usedFilters = this.originalOrder.filter((filterName) =>
      this.isFilterUsed(this.filters[filterName]),
    );

    const unusedFilters = this.originalOrder.filter(
      (filterName) => !this.isFilterUsed(this.filters[filterName]),
    );

    return [...usedFilters, ...unusedFilters];
  },

  isFilterUsed(filter) {
    if (filter === null) {
      return false;
    }

    if (Array.isArray(filter)) {
      return filter.length > 0;
    }

    return Object.values(filter).some((value) => value !== null);
  },
});

export default filter;
