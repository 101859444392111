export const getSearchParametersFromObject = (obj, prefix = '') => {
  const params = new URLSearchParams();

  function addParams(value, keyPrefix) {
    if (value === null || value === undefined || value === '') {
      // Dismiss null or undefined values
      return;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      // If the value is an object, recurse with square bracket notation
      Object.entries(value).forEach(([key, val]) => {
        const newKey = keyPrefix ? `${keyPrefix}[${key}]` : key;
        addParams(val, newKey);
      });
    } else if (Array.isArray(value)) {
      // If the value is an array, add each item with `[]`
      value.forEach((item, index) => {
        const newKey = `${keyPrefix}[${index}]`;
        addParams(item, newKey);
      });
    } else {
      // Add simple key-value pairs
      params.append(keyPrefix, value);
    }
  }

  addParams(obj, prefix);
  return params;
};
