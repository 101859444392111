import tagmanager from '../../abstracts/tagmanager.js';
import { getSearchParametersFromObject } from '../../abstracts/url.js';

const filterList = (filters, sort, country, federalState, page) => ({
  filters: filters,
  sort: sort,
  page: page,

  country: country,
  federalState: federalState,

  syncing: false,

  init() {
    const watchAndExecute = (property, callback) => {
      this.$watch(property, (newValue, oldValue) =>
        this.executeOnChange(newValue, oldValue, callback),
      );
    };

    watchAndExecute('sort', () => this.gotoPage(1));
    watchAndExecute('filters.search', () => this.gotoPage(1));

    watchAndExecute('filters.countryOrState', (value) =>
      this.filterNavigationSelected('locationEntryPoint', value),
    );
    watchAndExecute('filters.region', (value) => this.filterNavigationSelected('region', value));
    watchAndExecute('filters.typeOrCategory', (value) =>
      this.filterNavigationSelected('typeOrCategory', value),
    );
  },

  gotoPage(page) {
    this.page = page === 1 ? null : page;

    window.scrollTo({ top: 0, behavior: 'smooth' });

    this.syncState();
  },

  applyFilter(details) {
    this.trackEvent(
      details.initiator ? 'comp_quick-filter_submit' : 'comp_filter_submit',
      details.initiator,
    );

    this.gotoPage(1);
  },

  filterOpened(filterName) {
    this.trackEvent(filterName ? 'comp_quick-filter_open' : 'comp_filter_open', filterName);
  },

  filterClosed(filterName) {
    this.trackEvent(
      filterName !== 'all' ? 'comp_quick-filter_close' : 'comp_filter_close',
      filterName !== 'all' ? filterName : null,
    );
  },

  resetFilter(filterName) {
    this.trackEvent(
      filterName !== 'all' ? 'comp_quick-filter_reset' : 'comp_filter_reset',
      filterName !== 'all' ? filterName : null,
    );
  },

  executeOnChange(newValue, oldValue, callback) {
    newValue = newValue instanceof Object ? newValue.value : newValue;
    oldValue = oldValue instanceof Object ? oldValue.value : oldValue;

    if (oldValue !== newValue) {
      callback(newValue);
    }
  },

  trackEvent(event, selection) {
    tagmanager.event(event, {
      Category: 'tour',
      Selection: selection ?? false,
      Country: this.country,
      Federalstate: this.federalState,
    });
  },

  async syncState() {
    this.syncing = true;

    const url = this.getUrl();

    try {
      const response = await fetch(url, { method: 'POST' });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      history.replaceState(null, null, url);
      this.updateUI(data);
    } catch (error) {
      console.error('Failed to sync state:', error);

      window.location.reload();
    } finally {
      this.syncing = false;
    }
  },

  updateUI(data) {
    this.$refs.tours.innerHTML = data.tours;
    this.$refs.pagination.innerHTML = data.pagination;
    this.$refs.total.innerHTML = data.total;
  },

  filterNavigationSelected(navigationType, url) {
    this.trackEvent('comp_quick-filter_submit', navigationType);
    this.page = null;

    let searchParams = this.getSearchParams();
    window.location.href = url + (searchParams.toString() ? '?' + searchParams.toString() : '');
  },

  getUrl() {
    let searchParams = this.getSearchParams();

    return (
      location.protocol +
      '//' +
      location.host +
      location.pathname +
      (searchParams.toString() ? '?' + searchParams.toString() : '')
    );
  },

  getSearchParams() {
    return getSearchParametersFromObject({
      filters: {
        search: this.filters.search,
        difficulty: this.filters.difficulty,
        distance: this.filters.distance,
        duration: this.filters.duration,
        elevation: this.filters.elevation,
      },
      sort: this.sort,
      page: this.page,
    });
  },
});

export default filterList;
